export function battle() {
  const wsuri = 'wss://www.k2csgo.com/ws/'
  // const wsuri = "wss://cz-chunxinhuanbao.com/ws/";
  // const wsuri = "ws://192.168.101.12:7654/";
  // const wsuri = "ws://192.168.149.1:7654/";  'wss://www.gdskins.com/ws/'
  return wsuri
}
export function openBox() {
  const wsuri = 'wss://www.k2csgo.com/wss/'
  // const wsuri = "wss://cz-chunxinhuanbao.com/wss/";
  // const wsuri = "ws://192.168.101.12:7655/";
  // const wsuri = "ws://192.168.149.1:7655/";
  return wsuri
}
