<template>
	<div class="slide">
		<!-- <el-carousel indicator-position="outside" height="86px">
			<el-carousel-item v-for="(item, index) in listSlide" :key="index">
				<ul class="slide-ul">
					<li v-for="(item1, index1) in item" :key="index1" @click="getBox(item1.box_id)" :style="{
		      backgroundImage:'linear-gradient(' + item1.color1 + ',' + item1.color2 + ',' + item1.color3 + ',' + item1.color4 + ')',
		      borderColor:item1.color4
		    }">
						<div class="slide-warp">
							<div class="left">
								<img :src="item1.imageUrl"/>
							</div>
							<div class="right">
								<h4 class="r-2" :style="{color:item1.color}">{{ item1.skin_name }}</h4>
								<h5>
									打开 <span>{{ item1.box_name }}</span> 获得
								</h5>
								<h6>
									<img :src="item1.player_img" />
									<span style="color:#ADC8CB;">{{ item1.player_name }}</span>
								</h6>
							</div>
						</div>
						<span :style="{
		        backgroundColor: item1.color,
		      }"></span>
						<span class="back"></span>
					</li>
				</ul>
			</el-carousel-item> -->
		<!-- <div class="ul-line"></div> -->
		<!-- </el-carousel> -->
		<!-- :style="{
		  backgroundImage:'linear-gradient(' + item1.color1 + ',' + item1.color2 + ',' + item1.color3 + ',' + item1.color4 + ')',
		  borderColor:item1.color4
		}" -->
		<ul class="slide-ul" v-if="showNav">
			<li v-for="(item1, index1) in listSlide" :key="index1" @click="getBox(item1.box_id)" :style="{
		  backgroundImage: 'url('+getColorPz(item1.color)+')'}" :class="item1.news ? 'scrollAnimal':''">
				<!-- borderColor:item1.color -->
				<div class="slide-warp">
					<div class="left">
						<img :src="item1.imageUrl" />
					</div>
					<div class="right">
						<h4 class="r-2">{{ item1.skin_name }}</h4>
						<!-- <h5>
							打开 <span>{{ item1.box_name }}</span> 获得
						</h5>
						<h6>
							<img :src="item1.player_img" />
							<span style="color:#ADC8CB;">{{ item1.player_name }}</span>
						</h6> -->
					</div>
				</div>
				<div class="slide-warp1">
					<div class="left">
						<img :src="item1.player_img" />
					</div>
					<div class="right">
						<h4 class="r-2">{{ item1.player_name }}</h4>
					</div>
				</div>
				<!-- <div class="ul-line"></div> -->
			</li>
		</ul>
		<ul class="slide-ul" v-else>
			<li v-for="(item1, index1) in listSlide" :key="index1" @click="getBox(item1.box_id)" :style="{
		  backgroundImage: 'url('+getColorPz(item1.color)+')'}" :class="item1.news ? 'scrollAnimal':''">
				<!-- borderColor:item1.color -->
				<div class="slide-warp2">
					<div class="left">
						<img :src="item1.imageUrl" />
					</div>
					<div class="right">
						<h4 class="r-2">{{ item1.skin_name }}</h4>
						<!-- <h5>
							打开 <span>{{ item1.box_name }}</span> 获得
						</h5>
						<h6>
							<img :src="item1.player_img" />
							<span style="color:#ADC8CB;">{{ item1.player_name }}</span>
						</h6> -->
					</div>
				</div>
				<!-- <div class="slide-warp3">
					<div class="left">
						<img :src="item1.player_img" />
					</div>
					<div class="right">
						<h4 class="r-2">{{ item1.player_name }}</h4>
					</div>
				</div> -->
				<!-- <div class="ul-line"></div> -->
			</li>
		</ul>
		<!-- <div class="ul-line"></div> -->
		<!-- <div class="clear"></div> -->
	</div>
</template>

<script>
	export default {
		data() {
			return {
				listSlide: [
					// [],
					// [],
					// []
				],
			};
		},
		props: ['showNav'],
		methods: {
			getList() {
				let param = {
					page: 1,
					pageSize: 28,
				};
				var that = this;
				this.$axios
					.post("/index/Box/lately", this.$qs.stringify(param))
					.then((res) => {
						let data = res.data;
						this.listSlide = [];
						if (data.status == 1) {
							var thispage = 0;
							for (let i = 0; i < data.data.list.length; i++) {
								this.listSlide.push(data.data.list[i]);
							}
							var ontimeCount = 4;
							var cishu = data.data.list.length / ontimeCount;
							var thislist = JSON.parse(JSON.stringify(data.data.list));
							for (var z = 0; z < cishu; z++) {
								var c = 0;
								setTimeout(function() {
									that.listSlide.splice(-ontimeCount, ontimeCount);
									that.$forceUpdate()
									for (let is = 4 * c; is < (c * ontimeCount + 4); is++) {
										thislist[is]['news'] = true;
										that.listSlide.unshift(thislist[is]);
									}
									setTimeout(function() {
										that.listSlide.forEach(e => {
											e['news'] = false;
										})
										that.$forceUpdate()
									}, 2000)
									c++;
									that.$forceUpdate()
								}, 5000 * z)
							}
							setInterval(function() {
								that.$axios
									.post("/index/Box/lately", that.$qs.stringify(param))
									.then((res) => {
										let data = res.data;
										if (data.status == 1) {
											for (var z = 0; z < cishu; z++) {
												var c = 0;
												setTimeout(function() {
													that.listSlide.splice(-ontimeCount,
														ontimeCount);
													that.$forceUpdate()
													for (let is = 4 * c; is < (c * ontimeCount +
														4); is++) {
														thislist[is]['news'] = true;
														that.listSlide.unshift(thislist[is]);
													}
													setTimeout(function() {
														that.listSlide.forEach(e => {
															e['news'] = false;
														})
														that.$forceUpdate()
													}, 2000)
													c++;
													that.$forceUpdate()
												}, 5000 * z)
											}
										}
									});
							}, 5000 * cishu)
						}
					});
			},
			getRandomElementsFromArray(array, numElements) {
				if (numElements > array.length) {
					throw new Error("Number of elements to get is greater than array length.");
				}

				const shuffledArray = array.slice(); // Clone the array to avoid modifying the original
				for (let i = shuffledArray.length - 1; i > 0; i--) {
					const j = Math.floor(Math.random() * (i + 1));
					[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
				}

				return shuffledArray.slice(0, numElements);
			},

			getColorPz(color) {
				if (color == "#007acc") {
					return require('../assets/img/box-lan1.png');
				}
				if (color == "#8a2be2") {
					return require('../assets/img/box-zi1.png');
				}
				if (color == "#FFA500") {
					return require('../assets/img/box-jin1.png');
				}
				if (color == "#FF4500") {
					return require('../assets/img/box-bai1.png');
				}
			},
			transferColorToRgb(color) {
				if (typeof color !== "string" && !(color instanceof String))
					return console.error("请输入16进制字符串形式的颜色值");
				color = color.charAt(0) === "#" ? color.substring(1) : color;
				if (color.length !== 6 && color.length !== 3)
					return console.error("请输入正确的颜色值");
				if (color.length === 3) {
					color = color.replace(/(\w)(\w)(\w)/, "$1$1$2$2$3$3");
				}
				var reg = /\w{2}/g;
				var colors = color.match(reg);
				for (var i = 0; i < colors.length; i++) {
					colors[i] = parseInt(colors[i], 16).toString();
				}
				return "rgb(" + colors.join() + ")";
			},
			getBox(box_id) {
				this.$router.push({
					path: `/Openbox`,
					query: {
						box_id: box_id,
					},
				});
			},
		},
		activated() {
			// var that = this;
			// that.getList();
		},
		mounted() {
			this.getList();
		},
	};
</script>

<style lang="less" scoped>
	.slide /deep/ .el-carousel__indicators--outside {
		display: none;
	}

	::v-deep {
		.el-carousel__container {
			background-color: #10151b;
		}
	}

	.slide {
		position: relative;
		height: 100px;
		width: 100%;
		overflow: hidden;

		// padding: 0 0;
		.slide-ul {
			position: absolute;
			display: flex;
			flex-wrap: nowrap;
			overflow: hidden;
			// animation: run57 30s;
			// // 匀速滚动
			// animation-timing-function: linear;
			// // 反向
			// animation-direction: alternate;
			// animation-iteration-count: infinite; //播放几次动画
			// animation-delay: 0s; //动画运行前等待时间
			// animation-fill-mode: forwards !important; //动画结束 是否保持

			// @keyframes run57 {
			// 	0% {
			// 		transform: translate3d(0px, 0px, 0);
			// 	}

			// 	100% {
			// 		//50
			// 		transform: translate3d(-2400px, 0px, 0);
			// 	}
			// }
			.scrollAnimal {

				animation: scrollFirstChild 2s;

				@keyframes scrollFirstChild {
					0% {
						width: 0;
						opacity: 0;
					}

					100% {
						width: 140px;
						opacity: 1;
					}
				}
			}

			li:hover {
				top: -100px;
			}

			li {
				position: relative;
				top: 0;
				transition: all .5s;
				// background-image: linear-gradient(rgba(43, 44, 55, 0.5),
				// 		rgba(173, 200, 203, 0.5));
				background-size: 100% 100%;
				margin-left: 2px;
				height: 100px;


				.slide-warp {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 140px;
					height: 100px;
					overflow: hidden;

					.left {
						margin-right: 10px;

						//flex: 1 1 auto;
						img {
							height: 70px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
						}

						h5 {
							color: #848492;
							white-space: nowrap;
							font-weight: 200;
							font-size: 12px;

							span {
								color: #ADC8CB;
								font-size: 12px;
							}
						}

						h6 {
							display: flex;
							align-items: center;

							img {
								width: 20px;
								height: 20px;
								border-radius: 50%;
							}

							span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								margin-left: 5px;
								color: #848492;
								font-size: 12px;
							}
						}
					}
				}

				.slide-warp2 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 140px;
					height: 100px;
					overflow: hidden;

					.left {
						margin-right: 10px;

						//flex: 1 1 auto;
						img {
							height: 70px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
						}

						h5 {
							color: #848492;
							white-space: nowrap;
							font-weight: 200;
							font-size: 12px;

							span {
								color: #ADC8CB;
								font-size: 12px;
							}
						}

						h6 {
							display: flex;
							align-items: center;

							img {
								width: 20px;
								height: 20px;
								border-radius: 50%;
							}

							span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								margin-left: 5px;
								color: #848492;
								font-size: 12px;
							}
						}
					}
				}
				.slide-warp3 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 98px;
					height: 70px;
					overflow: hidden;
					background-color: #191926;

					.left {

						//flex: 1 1 auto;
						img {
							height: 50px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
							text-align: center;
						}
					}
				}

				.slide-warp1 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 140px;
					height: 100px;
					overflow: hidden;
					background-color: #191926;

					.left {

						//flex: 1 1 auto;
						img {
							height: 50px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
							text-align: center;
						}
					}
				}

				.line {
					position: absolute;
					bottom: 4px;
					width: 100%;
					height: 2px;
					background-color: #acc7ca;
				}

				.line1 {
					background-color: #b868b3;
				}

				.line2 {
					background-color: #f1a921;
				}

				// background-color: rgba(65, 105, 161, 0.4);
				// border-bottom: 2px solid #fff;

			}

			.li1 {
				background-image: linear-gradient(rgba(43, 44, 55, 0.5),
						rgba(185, 105, 212, 0.5));
			}

			.li2 {
				background-image: linear-gradient(rgba(43, 44, 55, 0.5),
						rgba(241, 169, 32, 0.5));
			}
		}

		.slide-ul:hover {
			cursor: pointer;
		}

		.slide-ul1 {
			position: absolute;
			display: flex;
			flex-wrap: nowrap;
			overflow: hidden;
			// animation: run57 30s;
			// // 匀速滚动
			// animation-timing-function: linear;
			// // 反向
			// animation-direction: alternate;
			// animation-iteration-count: infinite; //播放几次动画
			// animation-delay: 0s; //动画运行前等待时间
			// animation-fill-mode: forwards !important; //动画结束 是否保持

			// @keyframes run57 {
			// 	0% {
			// 		transform: translate3d(0px, 0px, 0);
			// 	}

			// 	100% {
			// 		//50
			// 		transform: translate3d(-2400px, 0px, 0);
			// 	}
			// }
			.scrollAnimal {

				animation: scrollFirstChild 2s;

				@keyframes scrollFirstChild {
					0% {
						width: 0;
						opacity: 0;
					}

					100% {
						width: 140px;
						opacity: 1;
					}
				}
			}

			// li:hover {
			// 	top: -100px;
			// }

			li {
				position: relative;
				top: 0;
				transition: all .5s;
				// background-image: linear-gradient(rgba(43, 44, 55, 0.5),
				// 		rgba(173, 200, 203, 0.5));
				background-size: 100% 100%;
				margin-left: 2px;
				height: 100px;


				.slide-warp {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 140px;
					height: 100px;
					overflow: hidden;

					.left {
						margin-right: 10px;

						//flex: 1 1 auto;
						img {
							height: 70px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
						}

						h5 {
							color: #848492;
							white-space: nowrap;
							font-weight: 200;
							font-size: 12px;

							span {
								color: #ADC8CB;
								font-size: 12px;
							}
						}

						h6 {
							display: flex;
							align-items: center;

							img {
								width: 20px;
								height: 20px;
								border-radius: 50%;
							}

							span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								margin-left: 5px;
								color: #848492;
								font-size: 12px;
							}
						}
					}
				}

				.slide-warp2 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 140px;
					height: 100px;
					overflow: hidden;

					.left {
						margin-right: 10px;

						//flex: 1 1 auto;
						img {
							height: 70px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
						}

						h5 {
							color: #848492;
							white-space: nowrap;
							font-weight: 200;
							font-size: 12px;

							span {
								color: #ADC8CB;
								font-size: 12px;
							}
						}

						h6 {
							display: flex;
							align-items: center;

							img {
								width: 20px;
								height: 20px;
								border-radius: 50%;
							}

							span {
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								margin-left: 5px;
								color: #848492;
								font-size: 12px;
							}
						}
					}
				}
				.slide-warp3 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 98px;
					height: 70px;
					overflow: hidden;
					background-color: #191926;

					.left {

						//flex: 1 1 auto;
						img {
							height: 50px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
							text-align: center;
						}
					}
				}

				.slide-warp1 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					width: 140px;
					height: 100px;
					overflow: hidden;
					background-color: #191926;

					.left {

						//flex: 1 1 auto;
						img {
							height: 50px;
							width: auto;
						}
					}

					.right {
						//flex: 2 1 auto;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;

						h4 {
							width: 100px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							color: white;
							font-weight: 400;
							font-size: 12px;
							text-align: center;
						}
					}
				}

				.line {
					position: absolute;
					bottom: 4px;
					width: 100%;
					height: 2px;
					background-color: #acc7ca;
				}

				.line1 {
					background-color: #b868b3;
				}

				.line2 {
					background-color: #f1a921;
				}

				// background-color: rgba(65, 105, 161, 0.4);
				// border-bottom: 2px solid #fff;

			}

			.li1 {
				background-image: linear-gradient(rgba(43, 44, 55, 0.5),
						rgba(185, 105, 212, 0.5));
			}

			.li2 {
				background-image: linear-gradient(rgba(43, 44, 55, 0.5),
						rgba(241, 169, 32, 0.5));
			}
		}

		.slide-ul1:hover {
			cursor: pointer;
		}
	}

	.ul-line {
		height: 2px;
		background-color: #fff;
		position: absolute;
		bottom: 0px;
		width: 100%;
		z-index: -1;
	}

	/deep/ .el-carousel__item.is-animating {
		transition: transform .4s ease-in-out;
	}

	@media screen and (max-width: 767px) {

		.slide {
			position: relative;
			height: 70px;
			width: 100%;
			overflow: hidden;

			// padding: 0 0;
			.slide-ul {
				position: absolute;
				display: flex;
				flex-wrap: nowrap;
				.scrollAnimal {

					animation: scrollFirstChild1 2s;

					@keyframes scrollFirstChild1 {
						0% {
							width: 0;
							opacity: 0;
						}

						100% {
							width: 98px;
							opacity: 1;
						}
					}
				}
				li:hover {
						top: 0px;
					}

				li {
					position: relative;
					top: 0;
					transition: all .5s;
					// background-image: linear-gradient(rgba(43, 44, 55, 0.5),
					// 		rgba(173, 200, 203, 0.5));
					background-size: 100% 100%;
					margin-left: 2px;
					height: 70px;


					.slide-warp2 {
						position: relative;
						display: flex;
						flex-direction: column;
						align-items: center;
						width: 98px;
						height: 70px;
						overflow: hidden;

						.left {
							margin-right: 0;

							//flex: 1 1 auto;
							img {
								height: 45px;
								width: auto;
							}
						}

						.right {
							//flex: 2 1 auto;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;

							h4 {
								width: 78px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								color: white;
								font-weight: 400;
								font-size: 8px;
							}

						}
					}

					.line {
						position: absolute;
						bottom: 4px;
						width: 100%;
						height: 2px;
						background-color: #acc7ca;
					}

					.line1 {
						background-color: #b868b3;
					}

					.line2 {
						background-color: #f1a921;
					}

					// background-color: rgba(65, 105, 161, 0.4);
					// border-bottom: 2px solid #fff;

				}

				.li1 {
					background-image: linear-gradient(rgba(43, 44, 55, 0.5),
							rgba(185, 105, 212, 0.5));
				}

				.li2 {
					background-image: linear-gradient(rgba(43, 44, 55, 0.5),
							rgba(241, 169, 32, 0.5));
				}
			}

			.slide-ul:hover {
				cursor: pointer;
			}
		}
	}
</style>