<template>
  <div class="doubt">
    <div class="doubt-warp">
      <div class="title">关于我们</div>
      <h3>k2csgo盲盒是国内专业的CSGO饰品盲盒网站。您在网站所获得的饰品可随时取回至自己的游戏账户，对接知名饰品交易平台，饰品种类多，发货快、溢价低。</h3>
      <h3>盲盒介绍：k2csgo盲盒开启必定会掉落饰品，且饰品掉落完全随机，公平公正；盲盒内的饰品为用户随机获取，以及饰品掉落的概率均在饰品图上予以公示，盲盒能够获得的饰品范围，以网站内具体图示和文字为准。</h3>
      <!-- <h3>k2csgo致力于提供随机、公平、有趣的游戏体验。</h3> -->
      <h3>k2csgo致力于提供随机、公平、有趣的游戏体验。</h3>
      <h3>温馨提示:理性消费，切勿上头</h3>
      <!-- <div class="title1">一、退款条款与免责条款</div>
      <div class="con">
        <span
          >1、用户理解并确认，在使用本条款项下的服务的过程中，可能会遇到不可抗力等风险因素，使得充值不到账情况。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成影响的客观事件，包括服务器异常、紧急突发bug、支付渠道延迟到账等问题。出现上述情况时，k2csgo.com
          将努力在第一时间配合，及时进行修复，并保证充值到账。但此项并不可以作为退款理由。</span
        ><br />

        <span
          >2、本条款项下的服务同大多数互联网服务一样，受包括但不限于用户原因、网络服务质量、社会环境等因素的差异影响，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本条款项下的服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。</span
        ><br />

        <span
          >3、用户理解并确认，本条款项下的服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，k2csgo.com
          及时进行修复，但在法律允许的范围内免责，并且拒绝不合理投诉。</span
        ><br />

        <span
          >4、用户理解并确认，在使用本条款项下的服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，k2csgo.com
          不承担任何责任。</span
        ><br />

        <span
          >5、用户理解并确认，k2csgo.com 需要定期或不定期地对 k2csgo.com
          或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，平台无需为此承担任何责任，但k2csgo.com
          应事先进行通告。</span
        >
      </div>

      <div class="title1" style="margin-top: 42px">二、取回条款</div>
      <div class="con">
        <span
          >1、当用户取回的饰品缺货时，可以向客服申请补货，如供货商暂时缺货，请耐心等待。</span
        >

        <span
          >2、如果出现了取回失败、取回延迟到账问题，k2csgo.com
          有义务第一时间为用户解决问题，保障用户权益。但这并不是用户退款或恶意投诉的理由。</span
        >

        <span
          >3、用户如果出现了误提，或账号被盗导致了提取，平台尽力帮助用户解决问题。但这并不是用户退款或恶意投诉的理由。</span
        >
      </div>
      <div class="title1" style="margin-top: 42px">三、其他条款与协议</div>
      <div class="con">
        用户理解并确认，k2csgo.com会在每日12.00进行日常更新，包括但不限于调整盲盒价格、盲盒饰品、盲盒概率等情况；由于市场波动、系统维护等不确定因素出现，k2csgo.com也会不定期进行更新，包括但不限于调整盲盒价格、盲盒饰品、盲盒概率等情况。
      </div> -->
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.doubt {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;
  padding-top: 42px;
  h3{
    font-size: 20px;
    color: #c3c3e2;
    margin: 80px 0;
  }
  .doubt-warp {
    width: 1200px;
    margin: 0 auto;
    padding: 32px;
  }
  .title {
    padding: 20px 0 64px 0;
    font-size: 30px;
    color: #c3c3e2;
  }
  .title1 {
    font-size: 20px;
    color: #c3c3e2;
    padding: 16px 0;
  }
  .con {
    font-size: 18px;
    color: #848492;
    span {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
@media screen and (max-width: 767px) {
  .doubt {
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    background-color: #1a1c24;
    padding-top: 42px;

    .doubt-warp {
      width: 80%;
      margin: 0 auto;
    }

    .title {
      font-size: 30px;
      color: #c3c3e2;
      font-size: 20px;
    }

    .con {
      margin-top: 12px !important;
      margin-bottom: 12px !important;
      font-size: 14px !important;
      color: #848492;

      h3 {
        color: #ddd;
        font-weight: normal;
        margin-top: 42px;
        font-size: 14px !important;
      }
    }

    .title1 {
      color: #c3c3e2;
      font-size: 14px;
      padding-bottom: 10px;
    }
  }
}
</style>